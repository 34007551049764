var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about__container"
  }, [_c('div', {
    staticClass: "about__lnb"
  }, [_c('ul', [_c('li', [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function01');
      }
    }
  }, [_vm._v(" 디자인 관리 ")])]), _c('li', {
    staticClass: "active"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function02');
      }
    }
  }, [_vm._v(" 반응형 지원 ")])]), _c('li', [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function03');
      }
    }
  }, [_vm._v(" 솔루션 연결 ")])])])]), _vm._m(0), _vm._m(1), _c('div', {
    staticClass: "about__other"
  }, [_c('article', [_vm._m(2), _c('ul', [_c('li', {
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function01');
      }
    }
  }, [_vm._m(3)]), _c('li', {
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function03');
      }
    }
  }, [_vm._m(4)])])])]), _c('section', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.getLoginStatus,
      expression: "!getLoginStatus"
    }],
    staticClass: "about_start about_start--responsive"
  }, [_c('article', [_vm._m(5), _c('button', {
    staticClass: "lnk__start--dark ga_f_23",
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/accounts/signup');
      }
    }
  }, [_vm._v(" 무료 시작하기 ")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about__visual about__visual--responsive"
  }, [_c('dl', [_c('dt', [_vm._v("반응형 지원")]), _c('dd', [_vm._v(" PC, 모바일을 유연하게 대응하는"), _c('br'), _vm._v("반응형 쇼핑몰을 쉽게 제작해보세요. ")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about__function"
  }, [_c('article', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_responsive01.png"),
      "alt": ""
    }
  }), _c('dl', [_c('dt', [_vm._v("PC, 모바일"), _c('br'), _vm._v("디자인을 한번에")]), _c('dd', [_vm._v(" 모든 해상도에서 유연하게 대응하는 반응형 디자인으로 제작되어 디바이스 별로 디자인을 제작하지 않고 1벌 제작으로 관리할 수 있습니다. ")])])]), _c('article', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_responsive02.png"),
      "alt": ""
    }
  }), _c('dl', [_c('dt', [_vm._v("모바일도 가능한"), _c('br'), _vm._v("디자인 에디터")]), _c('dd', [_vm._v(" 큐픽은 디바이스를 가리지 않고 웹브라우저를 통해 언제, 어디서나 디자인을 관리할 수 있도록 모바일 편집을 지원합니다. ")])])]), _c('article', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_responsive03.png"),
      "alt": ""
    }
  }), _c('dl', [_c('dt', [_vm._v("해상도에 따라 바뀌는"), _c('br'), _vm._v("콘텐츠 노출 기능")]), _c('dd', [_vm._v(" PC와 모바일 화면 해상도 차이에 따라 콘텐츠의 디자인 및 배치 순서를 다르게 구성하여 디바이스 해상도에 최적화된 사용자 경험을 제공해보세요. ")])])]), _c('article', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_responsive04.png"),
      "alt": ""
    }
  }), _c('dl', [_c('dt', [_vm._v("PC화면 디자인도"), _c('br'), _vm._v("모바일에서 한번에")]), _c('dd', [_vm._v(" 에디터 미리보기 화면에 맞게 자동으로 크기가 조절되어 모바일 편집에서도 PC디자인의 테마 스타일을 편집할 수 있습니다. ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', [_vm._v("다른 내용이")]), _vm._v(" 더 궁금하신가요?")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "about__other--design"
  }, [_c('dt', [_vm._v("디자인 관리")]), _c('dd', [_vm._v(" 트렌디한 테마와 컴포넌트 디자인으로"), _c('br'), _vm._v("내 쇼핑몰 디자인을 완성하세요. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "about__other--solution"
  }, [_c('dt', [_vm._v("솔루션 연결")]), _c('dd', [_vm._v(" 비즈니스에 맞는 솔루션과 연동하여"), _c('br'), _vm._v("바로 쇼핑몰 운영을 시작하세요. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("언제, 어디서나 관리하기 편한"), _c('br'), _vm._v("큐픽으로 시작하세요.")]);
}]

export { render, staticRenderFns }