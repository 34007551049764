<template>
  <div class="about__container">
    <div class="about__lnb">
      <ul>
        <li>
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function01')"
          >
            디자인 관리
          </button>
        </li>
        <li class="active">
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function02')"
          >
            반응형 지원
          </button>
        </li>
        <li>
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function03')"
          >
            솔루션 연결
          </button>
        </li>
      </ul>
    </div>
    <div class="about__visual about__visual--responsive">
      <dl>
        <dt>반응형 지원</dt>
        <dd>
          PC, 모바일을 유연하게 대응하는<br />반응형 쇼핑몰을 쉽게 제작해보세요.
        </dd>
      </dl>
    </div>
    <div class="about__function">
      <article>
        <img src="~@/assets/images/intro/img_about_responsive01.png" alt="" />
        <dl>
          <dt>PC, 모바일<br />디자인을 한번에</dt>
          <dd>
            모든 해상도에서 유연하게 대응하는 반응형 디자인으로 제작되어
            디바이스 별로 디자인을 제작하지 않고 1벌 제작으로 관리할 수
            있습니다.
          </dd>
        </dl>
      </article>
      <article>
        <img src="~@/assets/images/intro/img_about_responsive02.png" alt="" />
        <dl>
          <dt>모바일도 가능한<br />디자인 에디터</dt>
          <dd>
            큐픽은 디바이스를 가리지 않고 웹브라우저를 통해 언제, 어디서나
            디자인을 관리할 수 있도록 모바일 편집을 지원합니다.
          </dd>
        </dl>
      </article>
      <article>
        <img src="~@/assets/images/intro/img_about_responsive03.png" alt="" />
        <dl>
          <dt>해상도에 따라 바뀌는<br />콘텐츠 노출 기능</dt>
          <dd>
            PC와 모바일 화면 해상도 차이에 따라 콘텐츠의 디자인 및 배치 순서를
            다르게 구성하여 디바이스 해상도에 최적화된 사용자 경험을
            제공해보세요.
          </dd>
        </dl>
      </article>
      <article>
        <img src="~@/assets/images/intro/img_about_responsive04.png" alt="" />
        <dl>
          <dt>PC화면 디자인도<br />모바일에서 한번에</dt>
          <dd>
            에디터 미리보기 화면에 맞게 자동으로 크기가 조절되어 모바일
            편집에서도 PC디자인의 테마 스타일을 편집할 수 있습니다.
          </dd>
        </dl>
      </article>
    </div>
    <div class="about__other">
      <article>
        <p><span>다른 내용이</span> 더 궁금하신가요?</p>
        <ul>
          <li @click="$emit('movePage', '/introduction/function01')">
            <dl class="about__other--design">
              <dt>디자인 관리</dt>
              <dd>
                트렌디한 테마와 컴포넌트 디자인으로<br />내 쇼핑몰 디자인을
                완성하세요.
              </dd>
            </dl>
          </li>
          <li @click="$emit('movePage', '/introduction/function03')">
            <dl class="about__other--solution">
              <dt>솔루션 연결</dt>
              <dd>
                비즈니스에 맞는 솔루션과 연동하여<br />바로 쇼핑몰 운영을
                시작하세요.
              </dd>
            </dl>
          </li>
        </ul>
      </article>
    </div>
    <section
      class="about_start about_start--responsive"
      v-show="!getLoginStatus"
    >
      <article>
        <p>언제, 어디서나 관리하기 편한<br />큐픽으로 시작하세요.</p>
        <button
          class="lnk__start--dark ga_f_23"
          @click="$emit('movePage', '/accounts/signup')"
        >
          무료 시작하기
        </button>
      </article>
    </section>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import "@/assets/scss/intro.scss";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  data() {
    return {};
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoginStatus"])
  },
  methods: {},
  mounted() {},
  beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
div,
button {
  font-family: "Pretendard", "Malgun Gothic", sans-serif;
  color: #1c1e23;
  line-height: 1.25;
}
</style>
